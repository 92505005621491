import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { IncidentTypeEnum } from '../../models';
import { RSWithPosition } from '../../models/multipleAvailability';
import { TrackingBoardDTO } from '../dataProviders';

import { useTypedSelector } from './useTypedSelector';

export const useRollingStockCard = (availability: boolean) => {
	const [filteredRollingStockCardList, setFilteredRollingStockCardList] =
		useState<RsHook[]>([]);
	const [grafedRollingStockCardList, setGrafedRollingStockCardList] = useState<
		RsHook[]
	>([]);

	const rollingStockCard = useTypedSelector(
		(state) => state.rollingStockState.rollingStocks
	);

	const rollingStockCardWithoutAvailability = rollingStockCard.filter(
		(rollingStockCard) =>
			!('availability' in rollingStockCard) ||
			isEmpty(rollingStockCard.availability)
	);

	const allRollingStockList = availability
		? rollingStockCardWithoutAvailability
		: rollingStockCard;

	const filterByGRIncident = (
		rollingstocks: TrackingBoardDTO[]
	): TrackingBoardDTO[] => {
		return rollingstocks.filter((rs) =>
			rs.incidents.find(
				(incident) => incident.incidentTypeId === IncidentTypeEnum.GR
			)
		);
	};

	const allGrafedRollingStockList = availability
		? filterByGRIncident(rollingStockCardWithoutAvailability)
		: filterByGRIncident(rollingStockCard);

	const handleFilterRsCard = (contain: string) => {
		if (contain.trim() === '') handleClearFilterRsCard();
		setFilteredRollingStockCardList(
			handleSortRsCard(allRollingStockList).filter(({ name }) =>
				name.includes(contain)
			)
		);
	};

	const handleClearFilterRsCard = () => {
		setFilteredRollingStockCardList(handleSortRsCard(allRollingStockList));
		setGrafedRollingStockCardList(handleSortRsCard(allGrafedRollingStockList));
	};

	const handleSortRsCard = (RsCards: TrackingBoardDTO[]): RsHook[] => {
		return RsCards.map((rStock) => ({
			checked: false,
			id: rStock.id,
			isUsedOrder: rStock.isUsedOrder ?? 0,
			name: rStock.trainCode,
			NpDeltaTime: rStock.NpDeltaTime,
		})).sort(
			(a, b) =>
				Number(a.name.replace(/\D/g, '')) - Number(b.name.replace(/\D/g, ''))
		);
	};

	useEffect(() => {
		setFilteredRollingStockCardList(handleSortRsCard(allRollingStockList));
		setGrafedRollingStockCardList(handleSortRsCard(allGrafedRollingStockList));
	}, [rollingStockCard]);

	return {
		grafedRollingStockCardList: grafedRollingStockCardList,
		handleClearFilterRsCard,
		handleFilterRsCard,
		rollingStockCardList: filteredRollingStockCardList,
	};
};

export type RStock = {
	id: number;
	name: string;
	checked: boolean;
	isUsedOrder?: number;
};

type RsHook = RStock | RSWithPosition;
