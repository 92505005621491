/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { isEmpty, isEqual, isNil } from 'lodash';

import {
	IncidentSummary,
	IncidentTypeEnum,
	Item,
	RollingStockFilter,
	RSIncident,
	ServiceTypeEnum,
} from '../../../models';
import { TrackingBoardDTO } from '../../../packages/dataProviders';
import { getUniqueKey, MAL, NP } from '../../../packages/helpers';
import {
	CREATE_AVAILABILITY,
	DISPLAY_SPECIAL_OPERATION,
} from '../../../packages/helpers/rigths';
import {
	useDepartment,
	useModal,
	useRight,
	useTypedSelector,
} from '../../../packages/hooks';
import { useIncidentType } from '../../../packages/hooks/useIncidentType';
import { Badge, IconButton } from '../../../packages/ui';
import {
	AccessTime,
	AddIcon,
	AssignmentTurnedInIcon,
	WarningIcon,
} from '../../../packages/ui/icons';
import IncidentSvgPrintable from '../../../packages/ui/icons/IncidentSvgPrintable';
import theme from '../../../packages/ui/theme';
import { getIncidentTypes } from '../../../redux/thunks/incidentType';
import AvailabilityButton from '../avaibilityButton';

import RollingStockListComponent from './component';
import {
	StyledAnoCard,
	StyledIncidentCard,
	StyledIncidentContainer,
	StyledNoHoverIconBtn,
	StyledServiceRender,
} from './styles';

import '../../../assets/styles/constants/_colors.css';

const RollingStockList = () => {
	const dispatch = useDispatch();
	const { handleCloseModal, handleOpenModal, openModalState } = useModal();
	const { rsLabelwithUndefinedArticle } = useDepartment();
	const { incidentTypesSolve, getIncidentTypeName } = useIncidentType();
	const {
		can,
		canDisplayIncidentResolveButton,
		canDisplayIncidentCreateButton,
	} = useRight();

	const { TO_CLEAN } = RollingStockFilter;
	const [isResolvingIncident, setIsResolvingIncident] = useState(false);
	const [incidentSummary, setIncidentSummary] = useState<IncidentSummary>();
	const [selectedTrainId, setSelectedTrainId] = useState<number>();
	const [colorNp, setColorNp] = useState('');
	const [stockAfterByCodeFilter, setStockAfterByCodeFilter] = useState<
		TrackingBoardDTO[]
	>([]);

	const settingState = useTypedSelector((state) => state.settingState);
	const npHighLimit = useTypedSelector(
		(state) => state.settingState.npHighLimit
	);
	const isLoaded = useTypedSelector(
		({ apiState }) => apiState.rollingstock.isLoaded
	);
	const { rollingStocksTs, filterTs } = useTypedSelector(
		({ rollingStockState: { rollingStocks, filter } }) => {
			return {
				filterTs: filter,
				rollingStocksTs: rollingStocks,
			};
		}
	);

	const isGRIncident = (incidents: RSIncident[]) => {
		const grIncidents = incidents.filter((incident) =>
			isEqual(incident.incidentTypeId, IncidentTypeEnum.GR)
		);
		return grIncidents.length > 0;
	};

	const isResolvedNp = (row: any): boolean => {
		return (
			isEqual(row.availability?.serviceTypeId, ServiceTypeEnum.NP) &&
			isEqual(Number(row.NpDeltaTime), 0)
		);
	};

	const hasItems = (items: Item[]): boolean => {
		return !isEmpty(items);
	};

	const filterByCodeOrStatus = (
		rollingStocks: TrackingBoardDTO[],
		filter: FilterProps
	) => {
		if (filter.code) {
			return rollingStocks.filter((rollingStock) => {
				if (filter.code) {
					return rollingStock.trainCode
						.toLowerCase()
						.includes(filter.code.toLowerCase());
				}
			});
		} else if (filter.status) {
			return rollingStocks.filter((rollingStock) =>
				isEqual(filter.status, TO_CLEAN)
					? Number(rollingStock.NpDeltaTime) >= npHighLimit
					: isGRIncident(rollingStock.incidents)
			);
		} else return rollingStocks;
	};

	const applyFilter = useCallback(() => {
		if (!rollingStocksTs || rollingStocksTs.length < 1) return [];
		const filteredStocks = filterByCodeOrStatus(rollingStocksTs, filterTs);
		setStockAfterByCodeFilter(filteredStocks);
		return filteredStocks;
	}, [filterTs.code, filterTs.status, rollingStocksTs]);

	const getColorText = (row: any, cell: string): string => {
		const isNp = isEqual(cell, NP);
		const maxRef = isNp ? settingState.npHighLimit : settingState.malHighLimit;
		const minRef = isNp ? settingState.npLowLimit : settingState.malLowLimit;
		const formatRow = isNp ? Number(row.NpDeltaTime) : Number(row.MalDeltaTime);
		if (formatRow >= maxRef) return `${theme.palette.error.main}`;
		if (formatRow >= minRef) return `${theme.palette.warning.main}`;
		return theme.palette.text.primary;
	};

	useEffect(() => {
		if (isEmpty(incidentTypesSolve)) dispatch(getIncidentTypes());
	}, []);

	const resolveIncidentBtn = (trainId: number) =>
		canDisplayIncidentResolveButton() ? (
			<StyledAnoCard elevation={4}>
				<IconButton
					onClick={() => {
						setSelectedTrainId(trainId);
						handleOpenModal('incidentSelect');
						setIsResolvingIncident(true);
					}}
				>
					<AssignmentTurnedInIcon />
				</IconButton>
			</StyledAnoCard>
		) : (
			<></>
		);

	const specialOperationBtn = (specialOperations: [], availability: any) => (
		<StyledNoHoverIconBtn>
			<Badge
				badgeContent={
					specialOperations.length >= 2 ? specialOperations.length : 0
				}
				color="error"
			>
				<IncidentSvgPrintable name="OP" />
			</Badge>
			{availability && !isNil(availability.specialOperationTypeId) && (
				<AccessTime
					sx={{
						color: '#00AA91',
						left: '0.25rem',
						position: 'absolute',
						top: '0',
					}}
					fontSize="small"
				/>
			)}
		</StyledNoHoverIconBtn>
	);

	const formatValue = (value: string): number => {
		if (isNil(value)) return 0.1;
		return Number(value);
	};

	const formatRenderValue = (value: string): string => {
		return value ? value + 'j' : '-';
	};

	const getIncidentList = (
		trainId: number,
		incidents: IncidentRowDetails[],
		availability: AvailabilityRowDetails
	): IncidentSummary[] => {
		const counters: { [key: number]: number } = incidents.reduce(
			(
				acc: { [key: string]: number },
				incident: { incidentTypeId: number; isMultisolving: boolean }
			) => {
				acc[incident.incidentTypeId] = (acc[incident.incidentTypeId] || 0) + 1;
				return acc;
			},
			{}
		);

		return Object.entries(counters).map(([incidentTypeId, count]) => {
			const id = parseInt(incidentTypeId, 10);
			const incidentsOfType = incidents.filter((incident) =>
				isEqual(incident.incidentTypeId, id)
			);
			const isMultisolving = incidentsOfType.some(
				(incident) => incident.isMultisolving
			);

			const isPriority = incidents.some(
				({ incidentTypeId, isPriority }) =>
					isEqual(incidentTypeId, id) && isPriority
			);

			const incidentAvailability = incidents.some(({ incidentTypeId }) =>
				isEqual(incidentTypeId, availability?.incidentTypeId)
			);

			return {
				count,
				incidentType: {
					code: IncidentTypeEnum[id],
					id,
					incidentAvailability,
					isMultisolving,
					isPriority,
					name: getIncidentTypeName(id),
				},

				trainId,
			};
		});
	};

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

	const columns: GridColDef[] = [
		{
			field: 'trainCode',
			flex: 0.6,
			headerName: `N° ${rsLabelwithUndefinedArticle}`,
			minWidth: 150,
			renderCell: ({ row: { trainCode } }) => (
				<Typography variant="h5">{trainCode}</Typography>
			),
			type: 'string',
			valueGetter: ({ row: { trainCode } }) => trainCode,
		},
		{
			field: 'NpDeltaTime',
			flex: 0.6,
			headerName: 'NP',
			renderCell: ({ row, row: { NpDeltaTime, availability } }) => {
				return (
					<StyledServiceRender color={getColorText(row, NP)}>
						{formatRenderValue(NpDeltaTime)}
						{isEqual(availability?.serviceTypeId, 2) &&
							!isEqual(Number(NpDeltaTime), 0) && (
								<AccessTime
									fontSize="small"
									sx={{ color: '#00AA91', position: 'relative', top: '-10px' }}
								/>
							)}
					</StyledServiceRender>
				);
			},
			type: 'number',
			valueGetter: ({ row: { NpDeltaTime } }) => formatValue(NpDeltaTime),
		},
		{
			field: 'NcrDeltaTime',
			flex: 0.5,
			headerName: 'NCR',
			renderCell: ({ row: { NcrDeltaTime } }) =>
				formatRenderValue(NcrDeltaTime),
			type: 'number',
			valueGetter: ({ row: { NcrDeltaTime } }) => formatValue(NcrDeltaTime),
		},
		{
			field: 'NcDeltaTime',
			flex: 0.5,
			headerName: 'NC',
			renderCell: ({ row: { NcDeltaTime } }) => formatRenderValue(NcDeltaTime),
			type: 'number',
			valueGetter: ({ row: { NcDeltaTime } }) => formatValue(NcDeltaTime),
		},
		{
			field: 'MalDeltaTime',
			flex: 0.5,
			headerName: 'MAL',
			renderCell: ({ row, row: { MalDeltaTime } }) => (
				<StyledServiceRender color={getColorText(row, MAL)}>
					{formatRenderValue(MalDeltaTime)}
				</StyledServiceRender>
			),
			type: 'number',
			valueGetter: ({ row: { MalDeltaTime } }) => formatValue(MalDeltaTime),
		},
		...(can('displayPBService')
			? [
					{
						field: 'PbsDeltaTime',
						flex: 0.3,
						headerName: 'PB',
						renderCell: ({
							row: { PbsDeltaTime },
						}: {
							row: { PbsDeltaTime: any };
						}) => formatRenderValue(PbsDeltaTime),
						type: 'number',
						valueGetter: ({
							row: { PbsDeltaTime },
						}: {
							row: { PbsDeltaTime: any };
						}) => formatValue(PbsDeltaTime),
					},
			  ]
			: []),
		...(can(DISPLAY_SPECIAL_OPERATION)
			? [
					{
						disableExport: true,
						field: 'specialOperation',
						flex: 0.3,
						headerName: 'OP',
						minWidth: 80,
						renderCell: ({ row: { specialOperations, availability } }: any) =>
							hasItems(specialOperations) &&
							specialOperationBtn(specialOperations, availability),
					},
			  ]
			: []),
		{
			field: 'incidents',
			flex: 2,
			headerName: 'Anomalie(s) à traiter',
			minWidth: isPad ? 270 : undefined,
			renderCell: ({ row: { availability, incidents, id: trainId } }) => {
				return (
					<StyledIncidentContainer>
						{getIncidentList(trainId, incidents, availability).map(
							(incidentSummary) => (
								<IconButton
									key={getUniqueKey(incidentSummary.incidentType.code)}
									onMouseDown={() => {
										setSelectedTrainId(trainId);
										handleOpenModal('incidentReportEditForm');
										setIncidentSummary(incidentSummary);
									}}
								>
									<Badge
										badgeContent={
											incidentSummary.count >= 2 ? incidentSummary.count : 0
										}
										color="error"
									>
										<StyledIncidentCard
											elevation={6}
											border={
												isEqual(incidentSummary.incidentType.id, 0) &&
												incidentSummary.incidentType.isMultisolving
													? '3px solid var(--global-color-warning)'
													: 'none'
											}
										>
											<IncidentSvgPrintable
												name={incidentSummary.incidentType.code}
											/>
										</StyledIncidentCard>
									</Badge>
									{incidentSummary.incidentType.isPriority && (
										<WarningIcon
											sx={{
												bottom: '0',
												position: 'absolute',
												right: '0.25rem',
											}}
											fontSize="small"
											color="warning"
										/>
									)}
									{((incidentSummary.incidentType.incidentAvailability &&
										isEqual(
											incidentSummary.incidentType.id,
											availability?.incidentTypeId
										)) ||
										(isEqual(availability?.serviceTypeId, ServiceTypeEnum.NP) &&
											!isEqual(
												incidentSummary.incidentType.id,
												IncidentTypeEnum.GR
											))) && (
										<AccessTime
											sx={{
												color: '#00AA91',
												left: '0.25rem',
												position: 'absolute',
												top: '0',
											}}
											fontSize="small"
										/>
									)}
								</IconButton>
							)
						)}
						{canDisplayIncidentCreateButton() && (
							<StyledAnoCard elevation={4}>
								<IconButton
									onClick={() => {
										setSelectedTrainId(trainId);
										handleOpenModal('incidentSelect');
									}}
								>
									<AddIcon />
								</IconButton>
							</StyledAnoCard>
						)}
					</StyledIncidentContainer>
				);
			},
		},
		{
			disableExport: true, // hides column in datagrid export
			field: 'incident',
			flex: 0.6,
			headerName: 'Résoudre',
			minWidth: isPad ? 130 : undefined,
			renderCell: ({ row }) =>
				(hasItems(row.incidents) || hasItems(row.specialOperations)) &&
				resolveIncidentBtn(row.id),
		},
		{
			field: 'site',
			flex: 1,
			headerName: 'Site',
			minWidth: 150,
			type: 'string',
			valueGetter: ({ row }) =>
				isResolvedNp(row) ? '' : row.availability?.siteName,
		},
		{
			field: 'position',
			flex: 1,
			headerName: 'Position',
			minWidth: 150,
			type: 'string',
			valueGetter: ({ row }) =>
				isResolvedNp(row) ? '' : row.availability?.positionName,
		},
		...(can(CREATE_AVAILABILITY)
			? [
					{
						field: 'provision',
						flex: 1,
						minWidth: 200,
						renderCell: ({ row }: any) => (
							<AvailabilityButton
								getColorText={getColorText}
								handleOpenModal={handleOpenModal}
								row={row}
								setColorNp={setColorNp}
								setSelectedTrainId={setSelectedTrainId}
							/>
						),
					},
			  ]
			: []),
	];

	useEffect(() => {
		setStockAfterByCodeFilter(rollingStocksTs);
	}, [rollingStocksTs]);

	useEffect(() => {
		const filteredStock = applyFilter();
		setStockAfterByCodeFilter(filteredStock);
	}, [filterTs.code, applyFilter]);

	return (
		<RollingStockListComponent
			colorNp={colorNp}
			columns={columns}
			isLoaded={isLoaded}
			isPad={isPad}
			isResolvingIncident={isResolvingIncident}
			incidentSummary={incidentSummary}
			trainId={selectedTrainId}
			setIsResolvingIncident={setIsResolvingIncident}
			stockAfterByCodeFilter={stockAfterByCodeFilter}
			handleCloseModal={handleCloseModal}
			handleOpenModal={handleOpenModal}
			openModalState={openModalState}
		/>
	);
};

type FilterProps = {
	status?: RollingStockFilter;
	code?: string;
};

export default RollingStockList;

type IncidentRowDetails = {
	code: string;
	id: number;
	incidentTypeId: number;
	isMultisolving: boolean;
	isPriority: boolean;
	name: string;
};

type AvailabilityRowDetails = {
	incidentTypeId: number;
	serviceTypeId: number;
	isCurrent: boolean;
};
